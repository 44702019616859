import React from "react";

const routes = {
  key: "home",
  name: "Home",
  icon: "Home",
  path: "/",
  component: React.lazy(() => import("./pages/Dashboard")),
  children: [
    {
      key: "login",
      name: "Login",
      isPublic: true,
      isHidden: true,
      component: React.lazy(() => import("./pages/Login")),
    },
    {
      key: "profile",
      name: "Profile",
      isHidden: true,
    },
    {
      key: "company",
      name: "Company",
      isHidden: true,      
      component: React.lazy(() => import("./pages/Company")),
    },
    {
      key: "compare",
      name: "Compare",
      icon: "visualizeApp",
      component: React.lazy(() => import("./pages/Compare")),
    },
    {
      key: "visual",
      name: "Visual Information",
      icon: "visualizeApp",
      component: React.lazy(() => import("./pages/Visualinfo")),
    },
    {
      key: "comparetable",
      name: "Compare Table",
      icon: "visualizeApp",
      component: React.lazy(() => import("./pages/CompareTable")),
    },
  ],
};

export default routes;
