import React, { useState } from "react";
import { CommandBarButton, Modal, Stack } from "@fluentui/react";
import { useAuthentication } from "global/authentication";
//import { useHistory } from "react-router-dom";
import UserForm from "../UserForm/UserForm";

export function UserMenu() {
  const { principal, logout } = useAuthentication();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  //const history = useHistory();
  const menuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: "profile",
        text: "Profile",
        iconProps: { iconName: "PlayerSettings" },
        onClick: () => {
          openModal();
        },
      },
      {
        key: "logout",
        text: "Logout",
        iconProps: { iconName: "SignOut" },
        onClick: logout,
      },
    ],
  };

  return (
    <CommandBarButton
      menuProps={menuProps}
      iconProps={{ iconName: "UserOptional" }}
    >
      {principal.name}
      <Modal
        isOpen={isModalOpen}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="modal-container"
      ><Stack style={{margin: "10px"}}>
        <UserForm
          name={principal.name}
          email={principal.email}
          image={principal.picture}
          logOut={logout}
          closeModal={closeModal}
        /></Stack>
      </Modal>
    </CommandBarButton>
  );
}
