import { Label, Image, DefaultButton } from '@fluentui/react';
import React from 'react';

function UserForm({ name, email, image, logOut, closeModal }) {
  //console.log('UserForm.jsx')
  return (
    <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <Image src={image} alt="" />
      </div>
      <div>
        <Label htmlFor="name">{name}</Label>
      </div>
      <div>
        <Label htmlFor="email">{email}</Label>
      </div>
      <div>
        <DefaultButton onClick={logOut}>Log out</DefaultButton>
        <DefaultButton onClick={closeModal}>Close</DefaultButton>
      </div>
    </form>
  );
}

export default UserForm;
