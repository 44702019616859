import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { BlobServiceClient } from "@azure/storage-blob";

const connectionString = "SharedAccessSignature=sv=2021-10-04&ss=btqf&srt=sco&spr=https%2Chttp&st=2023-05-05T12%3A49%3A54Z&se=2023-08-06T12%3A49%3A00Z&sp=rl&sig=N%2FG9KLN6JHJfS8k9CIVNY7LDbBOBssiSU%2F5NKwO17tA%3D;BlobEndpoint=https://mcwmp.blob.core.windows.net;FileEndpoint=https://mcwmp.file.core.windows.net;QueueEndpoint=https://mcwmp.queue.core.windows.net;TableEndpoint=https://mcwmp.table.core.windows.net;";

function SearchBox(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState("");
  async function fetchsuggestiondata() {
    const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
    const containerClient = blobServiceClient.getContainerClient("yf-datafiles");
    const blobClient = containerClient.getBlockBlobClient("suggestions.json");
    const downloadResponse = await blobClient.download();
    const downloadedContent = await blobToString(await downloadResponse.blobBody);
    const rdata = JSON.parse(downloadedContent);
    setOptions(rdata);
    console.log(rdata);
  }
  useEffect(
    () => {
      fetchsuggestiondata();
    },[]);
  //const options = suggestionsData;

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      props.onSearch(selectedOption.value);
    }
  };
  async function blobToString(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

  return (
    <Select
      value={selectedOption}
      onChange={handleSelect}
      options={options}
      placeholder="Search..."
      isClearable
      isSearchable
      styles={{ control: (base) => ({ ...base, width: "100%" }) }}
    />
  );
}

export default SearchBox;
